import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BarcodeListenerDirective } from './barcode-listener.directive';
import { JsonApiPager } from './jsonapipager';
import { PenniesToDollarsPipe } from './pennies-to-dollars.pipe';


@NgModule({
  declarations: [
    BarcodeListenerDirective,
    PenniesToDollarsPipe,
  ],
  exports: [
    BarcodeListenerDirective,
    PenniesToDollarsPipe,
  ],
  imports: [
    CommonModule
  ]
})
export class SharedModule { }
