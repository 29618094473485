<div class="container">
    <h2>Select a Fundraiser</h2>

    <div class="row">
        <div *ngFor="let raffle of raffles" class="col-4">
            <div class="card">
                <div class="card-header">{{raffle.client.name}}</div>
                <div class="card-body">
                    <h5 class="card-title"><a [routerLink]="'/' + raffle.code">{{raffle.name}}</a></h5>
                </div>
            </div>
        </div>
        <div *ngFor="let sale of sales" class="col-4">
            <div class="card">
                <div class="card-header">{{sale.client.name}}</div>
                <div class="card-body">
                    <h5 class="card-title"><a [routerLink]="'/' + sale.code">{{sale.name}}</a></h5>
                </div>
            </div>
        </div>
    </div>
</div>