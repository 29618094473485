<div class="row">
  <p-card class="col-6 mx-auto" *ngIf="show_login || !seller">
    <div class="grid p-0 sm:p-1 lg:p-3">
      <div *ngIf="show_login || !seller" class="md:col-4 mx-auto">
        <form (submit)="onSubmitLogin()">
          <div class="form-group">
            <label for="book_code">Seller Code</label>
            <input type="text" class="form-control" name="book_code" placeholder="abc" [(ngModel)]="seller_code">
          </div>
          <div class="form-group">
            <label for="stats_pass">Password</label>
            <input type="password" class="form-control" name="stats_pass" [(ngModel)]="stats_pass">
          </div>
          <p-message severity="error" *ngIf="login_error" [text]="login_error"></p-message>
          <p-button type="submit" label="Submit"></p-button>
        </form>
      </div>
    </div>
  </p-card>

  <p-card *ngIf="seller" class="xl:col-10 col-12 mx-auto">
    <h1 class="text-center text-orange">Online Sales</h1>
    <div class="row form-inline">
      <div class="sm:col-10">
        <span class="h3">{{seller.name}} ({{seller.code}})</span>
      </div>
      <div class="sm:col-2">
        <button class="btn btn-sm btn-outline-secondary" (click)="forgetme()">Logout</button>
      </div>
    </div>

    <p-table [value]="transactions">
      <ng-template pTemplate="header">
        <tr>
          <th>Date</th>
          <th>Customer</th>
          <th *ngFor="let product of products">
            {{product.code}} - {{product.name}}
            ({{product.price | pennies | currency}})
          </th>
          <th>Total</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-tx>
        <tr>
          <td>{{tx.date | date:"medium"}}</td>
          <td>{{tx.customer}}</td>
          <td *ngFor="let product of products">
            {{tx.products[product.id]}}
          </td>
          <td>{{tx.total | pennies | currency}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <td [attr.colspan]="2 + products.length"></td>
          <td>{{total | pennies | currency}}</td>
        </tr>
      </ng-template>
    </p-table>
  </p-card>

</div>