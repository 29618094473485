import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { Book, Raffle } from '@app/types';
import { BookService } from '../book/book.service';
import { RaffleService } from './raffle.service';
import { CartService } from '@app/cart';
import { environment } from '@environments/environment';

@Component({
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.css']
})
export class PurchaseComponent implements OnInit {
  public book: Book;
  public bookcode: string;
  public raffle: Raffle;
  @Output() onAddToCart = new EventEmitter<number>();

  public coverImage: any;
  public ticketQuantity: number;

  public confirmNoSeller = false;
  public bookSearchText: string;
  public bookSearchResults: Book[];
  public searching = false;
  public searchFailed = false;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public bookservice: BookService,
    public cartservice: CartService,
    public raffleservice: RaffleService,
  ) { }


  ngOnInit(): void {
    this.raffleservice.raffle.subscribe(raffle => this.raffle = raffle);
    console.log(this.route.snapshot.routeConfig.path);
    if (this.route.snapshot.routeConfig.path == "prizes") {
      // Redirect to (PDF stored on) API server
      window.location.href = `${environment.apiUrl}/raffles/${this.raffle.id}/prizes`;
    }
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.bookcode = params.get('book');
      this.bookservice.getByCode(this.raffle.id, this.bookcode).subscribe(
        book => { this.book = book; }
      );
    });
  }

  addTicketsToCart() {
    const bookcode = this.bookcode ? this.bookcode : '000';
    this.cartservice.addTickets(this.ticketQuantity, bookcode, this.book ? this.book.owner : null);
    this.router.navigate(["cart"], { relativeTo: this.route.parent });
  }

  onBlur($event) {
    if (!this.bookcode && this.bookSearchResults.length == 1) {
      // User clicked away from the Search box when there was only a single search result
      // 2021.Feb - Josh Melanie and I decided to auto-select the single result
      this.onNewBookSelected(this.bookSearchResults[0]);
    }
  }

  onNewBookSelected(book: Book) {
    this.bookcode = book.code;
    this.router.navigate([book.code], { relativeTo: this.route });
  }

  searchBooks(event) {
    this.bookservice.search(this.raffle.id, event.query).subscribe(
      results => {
        this.bookSearchResults = results;
      }
    );
  }
}
