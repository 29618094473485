import { Component, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { BookService } from '@app/book';
import { Book, BookAdapter, Raffle } from '@app/types';
import { RaffleService } from '../raffle.service';

@Component({
  selector: 'app-sales-stats',
  templateUrl: './sales-stats.component.html',
  styleUrls: ['./sales-stats.component.css']
})
export class SalesStatsComponent implements OnInit {
  public raffle: Raffle;
  public book: Book;
  public book_code: string;
  public stats_pass: string;
  public stats: ReplaySubject<{}>;
  public show_login = false;
  public transactions = [];
  public ticket_count = 0;

  constructor(
    public raffleservice: RaffleService,
    public bookservice: BookService,
  ) { }

  ngOnInit(): void {
    // Wire up our stats watcher
    this.stats = new ReplaySubject<{}>(1);
    this.stats.subscribe({
      next: stats => {
        this.book = (new BookAdapter).adapt(stats["book"]);
        this.transactions = stats["transactions"];
        this.ticket_count = stats["total"];
      }
    });
    // Try to query for our stats first thing, in case we have a cookie with
    // saved credentials
    this.raffleservice.raffle.subscribe({
      next: raffle => {
        this.raffle = raffle;

        this.bookservice.getSalesStats(this.raffle.id).subscribe({
          next: stats => {
            this.stats.next(stats);
          },
          error: error => {
            console.error(error);
            this.show_login = true;
          }
        });
      }
    });
  }

  forgetme() {
    this.bookservice.forgetSalesStats(this.raffle.id).subscribe({
      next: response => {
        this.book = null;
        this.transactions = [];
        this.ticket_count = 0;
        this.show_login = true;
      }
    });
  }

  onSubmitLogin() {
    this.show_login = false;
    this.bookservice.getSalesStats(this.raffle.id, this.book_code, this.stats_pass).subscribe({
      next: stats => {
        this.stats.next(stats);
      },
      error: error => {
        console.error(error);
        this.show_login = true;
      }
    });
  }
}
