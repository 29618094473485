<div class="row">
  <div class="col-md ml-auto">
    <div class="card card-rounded">
      <div class="card-body p-0 sm:p-1 lg:p-3">
        <div *ngIf="show_login || !book" class="md:col-4 mx-auto">
          <form (submit)="onSubmitLogin()">
            <div class="form-group">
              <label for="book_code">Booklet Code</label>
              <input type="text" class="form-control" name="book_code" placeholder="abc" [(ngModel)]="book_code">
            </div>
            <div class="form-group">
              <label for="stats_pass">Password</label>
              <input type="password" class="form-control" name="stats_pass" [(ngModel)]="stats_pass">
            </div>
            <button type="submit" class="btn btn-primary">Submit</button>
          </form>
        </div>

        <div *ngIf="book && transactions" class="lg:col-8 col-12 mx-auto">
          <h1 class="text-center text-orange">Online Sales</h1>
          <div class="row form-inline">
            <div class="sm:col-10">
              <span class="h3">{{book.owner}} ({{book.code}})</span>
            </div>
            <div class="sm:col-2">
              <button class="btn btn-sm btn-outline-secondary" (click)="forgetme()">Logout</button>
            </div>
          </div>
          <div class="row">
            <h3 class="sm:col-5">Date</h3>
            <h3 class="col-6 col-offset-1 sm:col-4 sm:col-offset-0">Customer</h3>
            <h3 class="col-5 sm:col-3">Tickets</h3>
          </div>
          <div class="row" class="text-center" *ngIf="!transactions">
            <i>None</i>
          </div>
          <ng-container *ngFor="let tx of transactions | keyvalue">
            <div class="row mb-2 sm:mb-0">
              <div class="sm:col-5">{{tx.value["date"] | date:"medium"}}</div>
              <div class="col-6 col-offset-1 sm:col-4 sm:col-offset-0">{{tx.value["customer"]}}</div>
              <div class="col-5 sm:col-3">{{tx.value["tickets"]}}</div>
            </div>
          </ng-container>
          <div class="row" *ngIf="ticket_count">
            <h4 class="col-7 sm:col-4 sm:col-offset-5 text-right">Total</h4>
            <h4 class="col-5 sm:col-3">{{ticket_count}}</h4>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>