import { Injectable } from '@angular/core';
import { Adapter } from '@app/shared/adapter';
import { environment } from '@environments/environment';

export class Deletable {
  public is_deleting: boolean = false;
}
export class Updatable {
  public is_updating: boolean = false;
}
export class DeletableAndUpdatable {
  public is_deleting: boolean = false;
  public is_updating: boolean = false;
}


/*********************************************************************************************************
 * ADDRESS
 *********************************************************************************************************/

 export class Address {
  constructor(
    public id: string,
    public address1: string,
    public address2: string,
    public city: string,
    public state: string,
    public zip: string,
  ) { }
}

@Injectable({
  providedIn: "root",
})
export class AddressAdapter implements Adapter<Address> {
  adapt(item: any): Address {
    let a = new Address(
      item.id,
      item.address1,
      item.address2,
      item.city,
      item.state,
      item.zip,
    );
    return a;
  }
}

/*********************************************************************************************************
 * BOOK
 *********************************************************************************************************/

export class Book {
  constructor(
    public id: string,
    public raffle_id: string,
    public code: string,
    public barcode: string,
    public owner: string,
    public grade: string,
    public classroom: string,
    public online_privacy: boolean,
    public physical_money_collected: number,
    public tickets_count: number,
    public tickets_sold: number,
    public tickets_physical_count: number,
    public tickets_physical_sold: number,
    public tickets_virtual_sold: number,
    public tickets: Ticket[],
  ) { }
}

@Injectable({
  providedIn: "root",
})
export class BookAdapter implements Adapter<Book> {
  adapt(item: any): Book {
    //console.log("BookAdapter raw object: " + JSON.stringify(item));
    let tickets = [];
    if (item.tickets) {
      let ta = new TicketAdapter();
      item.tickets.forEach(ticket => {
        tickets.push(ta.adapt(ticket));
      });
    }
    let b = new Book(
      item.id,
      item.raffle_id,
      item.code,
      item.barcode,
      item.owner,
      item.grade,
      item.classroom,
      item.online_privacy,
      item.physical_money_collected,
      item.tickets_count,
      item.tickets_sold,
      item.tickets_physical_count,
      item.tickets_physical_sold,
      item.tickets_virtual_sold,
      tickets
    );
    //console.log("BookAdapter returning: " + JSON.stringify(b));
    return b;
  }
}

/*********************************************************************************************************
 * CLIENT
 *********************************************************************************************************/

export class Client {
  public stripe_data: ClientStripeData;

  constructor(
    public id: string,
    public code: string,
    public name: string,
    public address: string,
    public phone: string,
    public contact_name: string,
    public contact_email: string,
    public payment_processor: string,
    public payment_processor_data: {},
    public raffles: Raffle[],
  ) {
    if (this.payment_processor == "STRIPE") {
      this.stripe_data = new ClientStripeAdapter().adapt(this.payment_processor_data);
    }
  }
}

@Injectable({
  providedIn: "root",
})
export class ClientAdapter implements Adapter<Client> {
  adapt(item: any): Client {
    //console.log("Adapting raw object: " + JSON.stringify(item));
    let raffles = [];
    if (item.raffles) {
      let ra = new RaffleAdapter();
      item.raffles.forEach(raffle => {
        raffles.push(ra.adapt(raffle));
      });
    }
    let c = new Client(
      item.id,
      item.code,
      item.name,
      item.address,
      item.phone,
      item.contact_name,
      item.contact_email,
      item.payment_processor,
      item.payment_processor_data,
      raffles,
    );
    //console.log("Adapter returning: " + JSON.stringify(c));
    return c;
  }
}

/*********************************************************************************************************
 * CLIENTSTRIPEDATA
 *********************************************************************************************************/

export class ClientStripeData {
  constructor(
    public publishable_key: string,
  ) {}
}

@Injectable({
  providedIn: "root",
})
export class ClientStripeAdapter implements Adapter<ClientStripeData> {
  adapt(item: any): ClientStripeData {
    //console.log("CSD adapting raw object: " + JSON.stringify(item));
    let c = new ClientStripeData(
      item.publishable_key,
    );
    //console.log("CSD adapter returning: " + JSON.stringify(c));
    return c;
  }
}

/*********************************************************************************************************
 * PAYMENT
 *********************************************************************************************************/

export class Payment {
  //public stripe_data: StripePayment;

  constructor(
    public id: string,
    public transaction_id: string,
    public amount: number,
    public status: string,
    public processor: string,
    public processor_data: string,
  ) {
    if (this.processor == "STRIPE") {
      //this.stripe_data = new ClientStripeAdapter().adapt(this.processor_data);
    }

  }
}

@Injectable({
  providedIn: "root",
})
export class PaymentAdapter implements Adapter<Payment> {
  adapt(item: any): Payment {
    //console.log("PaymentAdapter raw object: " + JSON.stringify(item));
    let p = new Payment(
      item.id,
      item.transaction_id,
      item.amount,
      item.status,
      item.processor,
      item.processor_data,
    );
    //console.log("PaymentAdapter returning: " + JSON.stringify(p));
    return p;
  }
}


export class Product {
  constructor(
    public id: string = null,
    public sale_id: string = null,
    public code: string = null,
    public name: string = null,
    public description: string = null,
    public image_urls: string[] = [],
    public price: number = null,
  ) {
  }
}

@Injectable({
  providedIn: "root",
})
export class ProductAdapter implements Adapter<Product> {
  adapt(item: any): Product {
    //console.log("Adapting raw product object: " + JSON.stringify(item));
    let p = new Product(
      item.id,
      item.sale_id,
      item.code,
      item.name,
      item.description,
      [],
      item.price,
    );
    // Fully-qualify the image URLs if necessary
    for (let url of item.image_urls) {
      if (url.substring(0, 4) != "http") {
        url = environment.apiUrl + "/" + url;
      }
      p.image_urls.push(url);
    }
    //console.log("Adapter returning Product: " + JSON.stringify(p));
    return p;
  }
}


/*********************************************************************************************************
 * RAFFLE
 *********************************************************************************************************/

export class Raffle {
  public client: Client;
  public books: Book[];
  public ticket_price_dollars: number;

  constructor(
    public id: string,
    public code: string,
    public name: string,
    public client_id: string,
    public start: Date,
    public end: Date,
    public ticket_price: number,
    public tickets_per_book: number,
    public about: string,
    public terms: string,
    public discount_tier1_break: number,
    public discount_tier1_credit: number,
    public discount_tier1_freetickets: number,
    public discount_tier2_break: number,
    public discount_tier2_credit: number,
    public discount_tier2_freetickets: number,
    public discount_tier3_break: number,
    public discount_tier3_credit: number,
    public discount_tier3_freetickets: number,
    public books_count: number,
    public tickets_count: number,
    public tickets_physical_count: number,
    public tickets_physical_sold: number,
    public tickets_virtual_sold: number,
    public sales_physical: number,
    public sales_virtual: number,
    public banner_style: object,
  ) {
    this.books = [];
    this.ticket_price_dollars = this.ticket_price / 100;
  }

}

@Injectable({
  providedIn: "root",
})
export class RaffleAdapter implements Adapter<Raffle> {
  adapt(item: any): Raffle {
    //console.log("Adapting raw raffle object: " + JSON.stringify(item));
    let r = new Raffle(
      item.id,
      item.code,
      item.name,
      item.client_id,
      new Date(item.start),
      new Date(item.end),
      item.ticket_price,
      item.tickets_per_book,
      item.about,
      item.terms,
      item.discount_tier1_break,
      item.discount_tier1_credit,
      item.discount_tier1_freetickets,
      item.discount_tier2_break,
      item.discount_tier2_credit,
      item.discount_tier2_freetickets,
      item.discount_tier3_break,
      item.discount_tier3_credit,
      item.discount_tier3_freetickets,
      item.books_count,
      item.tickets_count,
      item.tickets_physical_count,
      item.tickets_physical_sold,
      item.tickets_virtual_sold,
      item.sales_physical,
      item.sales_virtual,
      item.banner_style,
    );
    if (item.client) {
      r.client = new ClientAdapter().adapt(item.client);
    }
    if (item.books) {
      let ba = new BookAdapter();
      for (const bookitem of item.books) {
        r.books.push(ba.adapt(bookitem));
      }
    }
    //console.log("Adapter returning Raffle: " + JSON.stringify(r));
    return r;
  }
}

/*********************************************************************************************************
 * SALE
 *********************************************************************************************************/

export class Sale {
  public client: Client;
  public products: Product[];
  public sellers: Seller[];

  constructor(
    public id: string = null,
    public client_id: string = null,
    public code: string = null,
    public name: string = null,
    public start: Date = null,
    public end: Date = null,
    public about: string = null,
    public terms: string = null,
    public banner_style: object,
  ) {
    this.products = [];
    this.sellers = [];
  }

}

@Injectable({
  providedIn: "root",
})
export class SaleAdapter implements Adapter<Sale> {
  adapt(item: any): Sale {
    //console.log("Adapting raw sale object: " + JSON.stringify(item));
    let s = new Sale(
      item.id,
      item.client_id,
      item.code,
      item.name,
      new Date(item.start),
      new Date(item.end),
      item.about,
      item.terms,
      item.banner_style,
      );
    if (item.client) {
      s.client = new ClientAdapter().adapt(item.client);
    }
    if (item.products) {
      let pa = new ProductAdapter();
      for (const subitem of item.products) {
        s.products.push(pa.adapt(subitem));
      }
    }
    if (item.sellers) {
      let sa = new SellerAdapter();
      for (const subitem of item.sellers) {
        s.sellers.push(sa.adapt(subitem));
      }
    }
    //console.log("Adapter returning Sale: " + JSON.stringify(s));
    return s;
  }
}

/*********************************************************************************************************
 * SALECART
 *********************************************************************************************************/

export class SaleCart {
  public price: number;
  public product: Product;
  public productcode: string;
  public productname: string;
  public quantity: number;
  public seller: Seller;
  public sellercode: string;
  public sellername: string;
}

/*********************************************************************************************************
 * SALETRANSACTION
 *********************************************************************************************************/

export class SaleTransaction extends DeletableAndUpdatable {
  constructor(
    public id: string,
    public sale_id: string,
    public status: string,
    public cart: SaleCart[],
    public subtotal: number,
    public credit: number,
    public tax: number,
    public donation_fees: number,
    public donation_other: number,
    public total: number,
    public customer_name: string,
    public customer_email: string,
    public customer_phone: string,
    public ship_address1: string,
    public ship_address2: string,
    public ship_city: string,
    public ship_state: string,
    public ship_zip: string,
    public payments: Payment[],
  ) {
    super();
   }
}

@Injectable({
  providedIn: "root",
})
export class SaleTransactionAdapter implements Adapter<SaleTransaction> {
  adapt(item: any): SaleTransaction {
    let payments = [];
    if (item.payments) {
      let pa = new SaleTransactionPaymentAdapter();
      item.payments.forEach(payment => {
        payments.push(pa.adapt(payment));
      });
    }

    let t = new SaleTransaction(
      item.id,
      item.sale_id,
      item.status,
      item.cart,
      item.subtotal,
      item.credit,
      item.tax,
      item.donation_fees,
      item.donation_other,
      item.total,
      item.customer_name,
      item.customer_email,
      item.customer_phone,
      item.ship_address1,
      item.ship_address2,
      item.ship_city,
      item.ship_state,
      item.ship_zip,
      payments,
    );
    return t;
  }
}

/*********************************************************************************************************
 * SALETRANSACTIONPAYMENT
 *********************************************************************************************************/

 export class SaleTransactionPayment {
  //public stripe_data: StripePayment;

  constructor(
    public id: string,
    public transaction_id: string,
    public amount: number,
    public fee: number,
    public net: number,
    public status: string,
    public processor: string,
    public processor_data: string,
  ) {
    if (this.processor == "STRIPE") {
      //this.stripe_data = new ClientStripeAdapter().adapt(this.processor_data);
    }

  }
}

@Injectable({
  providedIn: "root",
})
export class SaleTransactionPaymentAdapter implements Adapter<SaleTransactionPayment> {
  adapt(item: any): SaleTransactionPayment {
    //console.log("PaymentAdapter raw object: " + JSON.stringify(item));
    let p = new SaleTransactionPayment(
      item.id,
      item.transaction_id,
      item.amount,
      item.fee,
      item.net,
      item.status,
      item.processor,
      item.processor_data,
    );
    //console.log("PaymentAdapter returning: " + JSON.stringify(p));
    return p;
  }
}

/*********************************************************************************************************
 * SELLER
 *********************************************************************************************************/

export class Seller {
  constructor(
    public id: string = null,
    public sale_id: string = null,
    public code: string = null,
    public name: string = null,
    public grade: string = null,
    public classroom: string = null,
    public stats_pass: string = null,
  ) { }
}

@Injectable({
  providedIn: "root",
})
export class SellerAdapter implements Adapter<Seller> {
  adapt(item: any): Seller {
    //console.log("Adapting raw seller object: " + JSON.stringify(item));
    let s = new Seller(
      item.id,
      item.sale_id,
      item.code,
      item.name,
      item.grade,
      item.classroom,
      item.stats_pass,
    );
    //console.log("Adapter returning Seller: " + JSON.stringify(s));
    return s;
  }
}


/*********************************************************************************************************
 * TICKET
 *********************************************************************************************************/

export class Ticket {
  constructor(
    public id: string,
    public book_id: string,
    public seq: string,
    public barcode: string,
    public sold: boolean,
    public customer_name: string,
    public customer_email: string,
    public customer_phone: string,
    public transaction_id: string,
    public book: Book,
  ) { }
}

@Injectable({
  providedIn: "root",
})
export class TicketAdapter implements Adapter<Ticket> {
  adapt(item: any): Ticket {
    //console.log("TicketAdapter raw object: " + JSON.stringify(item));
    let book = null;
    if (item.book) {
      let ba = new BookAdapter();
      book = ba.adapt(item.book);
    }
    let t = new Ticket(
      item.id,
      item.book_id,
      item.seq,
      item.barcode,
      item.sold,
      item.customer_name,
      item.customer_email,
      item.customer_phone,
      item.transaction_id,
      book
    );
    //console.log("TicketAdapter returning: " + JSON.stringify(t));
    return t;
  }
}

/*********************************************************************************************************
 * TRANSACTION
 *********************************************************************************************************/

export class CartTicket {
  constructor(
    public code: string,
    public seller?: string,
  ) {}
}

export class Transaction extends DeletableAndUpdatable {
  constructor(
    public id: string,
    public raffle_id: string,
    public status: string,
    public cart: CartTicket[],
    public freetickets: any[],
    public subtotal: number,
    public credit: number,
    public tax: number,
    public donation_fees: number,
    public donation_other: number,
    public total: number,
    public customer_name: string,
    public customer_email: string,
    public customer_phone: string,
    public payments: Payment[],
  ) {
    super();
  }
}

@Injectable({
  providedIn: "root",
})
export class TransactionAdapter implements Adapter<Transaction> {
  adapt(item: any): Transaction {
    //console.log("TransAdapter raw object: " + JSON.stringify(item));
    let payments = [];
    if (item.payments) {
      let pa = new PaymentAdapter();
      item.payments.forEach(payment => {
        payments.push(pa.adapt(payment));
      });
    }

    let t = new Transaction(
      item.id,
      item.raffle_id,
      item.status,
      item.cart,
      item.freetickets,
      item.subtotal,
      item.credit,
      item.tax,
      item.donation_fees,
      item.donation_other,
      item.total,
      item.customer_name,
      item.customer_email,
      item.customer_phone,
      payments,
    );
    //console.log("TransAdapter returning: " + JSON.stringify(t));
    return t;
  }
}
