import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { NgxStripeModule } from 'ngx-stripe';
import { MarkdownModule } from 'ngx-markdown';

import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';

import { SharedModule } from '@app/shared/shared.module';
import { RaffleModule } from '@app/raffle/raffle.module';
import { SaleModule } from '@app/sales/sale.module';

import { AppRoutingModule } from './app-routing.module';
import { AppRoutingService } from './app-routing.service';
import { AppComponent } from './app.component';
import { HomeComponent } from './home';
import { PageNotFoundComponent } from './_helpers';
import { CartComponent } from './cart/cart.component';
import { SaleCartComponent } from './cart/salecart.component';
import { PayStripeComponent } from './cart/pay-stripe.component';
import { ThankYouComponent } from './cart/thankyou/thankyou.component';

export function AppInitializer (appRoutingService: AppRoutingService) {
  return function () { return appRoutingService.initializeRoutes(); };
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PageNotFoundComponent,
    CartComponent,
    SaleCartComponent,
    PayStripeComponent,
    ThankYouComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    BadgeModule,
    ButtonModule,
    CardModule,
    CheckboxModule,
    DropdownModule,
    InputNumberModule,
    InputTextModule,
    MessageModule,
    TableModule,
    TooltipModule,
    NgxStripeModule.forRoot(),
    MarkdownModule.forRoot(),
    SharedModule,
    RaffleModule,
    SaleModule,
    AppRoutingModule, // <-- should always be last since it contains the '**' catch-all
  ],
  providers: [
    AppRoutingService,
    Title,
    {
      provide : APP_INITIALIZER,
      useFactory : AppInitializer,
      deps : [AppRoutingService],
      multi : true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
