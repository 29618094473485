import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CartService } from './cart.service';
import { RaffleService } from '@app/raffle';
import { BookService } from '@app/book';
import { Raffle, Transaction } from '@app/types';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  public raffle: Raffle;
  public transaction: Transaction;
  public include_fees = false;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public raffleservice: RaffleService,
    public bookservice: BookService,
    public cartservice: CartService,
  ) {

  }

  ngOnInit(): void {
    // Enable include_fees by default
    this.include_fees = true;

    this.raffleservice.raffle.subscribe({
      next: raffle => {
        this.raffle = raffle;
      }
    });
    this.cartservice.transaction.subscribe({
      next: trans => {
        if (trans == null) {
          // We don't have a transaction ID which means our cart is empty, which means either this
          // page was navigated to by mistake or (more likely) timed out
          // Redirect back to the purchase page
          this.router.navigate([".."], {relativeTo: this.route});
        }
        if (trans.status == "COMPLETE") {
          // Payment is complete for this transaction...
          this.cartservice.forgetTransaction();
          this.router.navigate(["thankyou"], {relativeTo: this.route.parent});
        }
        this.include_fees = this.cartservice.current_transaction.donation_fees > 0;
        if (this.include_fees) {
          // Re-calculate the donation fees in case their subtotal changed (added more tickets to their cart)
          let new_fees = this.cartservice.calculateStripeNetTotal(this.cartservice.current_transaction.subtotal).fee;
          if (new_fees != this.cartservice.current_transaction.donation_fees) {
            this.cartservice.current_transaction.donation_fees = new_fees;
            this.cartservice.save().subscribe();
          }
        }
      }
    });
  }

  emptyCart(): void {
    this.cartservice.empty().subscribe(
      data => {
        this.router.navigate([".."], {relativeTo: this.route});
      }
    );
  }

  onPaymentError(event) {
    this.cartservice.pollTransaction();
  }

  onPaymentSuccess(event) {
    this.cartservice.updateCustomerInfo(event.name, event.email, event.phone);
  }

  onToggleFees(event) {
    if (this.include_fees) {
      this.cartservice.current_transaction.donation_fees = this.cartservice.calculateStripeNetTotal(this.cartservice.current_transaction.subtotal).fee;
    } else {
      this.cartservice.current_transaction.donation_fees = 0;
    }
    this.cartservice.save().subscribe();
  }
}
