import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PurchaseComponent } from './purchase.component';
import { CartComponent } from '../cart/cart.component';
import { RaffleExpiredComponent } from './expired.component';
import { ThankYouComponent } from '../cart/thankyou/thankyou.component';
import { SalesStatsComponent } from './stats/sales-stats.component';

const routes: Routes = [
  { path: '', component: PurchaseComponent },
  { path: 'cart', component: CartComponent },
  { path: 'expired', component: RaffleExpiredComponent },
  { path: 'prizes', component: PurchaseComponent },
  { path: 'stats', component: SalesStatsComponent },
  { path: 'thankyou', component: ThankYouComponent },
  { path: ':book', component: PurchaseComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class RaffleRoutingModule { }