import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// primeng things
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BadgeModule } from 'primeng/badge';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';

import { MarkdownModule } from 'ngx-markdown';
import { RaffleRoutingModule } from './raffle-routing.module';

import { SharedModule } from '@app/shared/shared.module';

import { RaffleComponent } from './raffle.component';
import { RaffleExpiredComponent } from './expired.component';
import { PurchaseComponent } from './purchase.component';
import { SalesStatsComponent } from './stats/sales-stats.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RaffleRoutingModule,
    RouterModule,
    SharedModule,
    AccordionModule,
    AutoCompleteModule,
    BadgeModule,
    CardModule,
    InputTextModule,
    MarkdownModule.forChild(),
  ],
  declarations: [
    RaffleComponent,
    RaffleExpiredComponent,
    PurchaseComponent,
    SalesStatsComponent,
  ],
  exports: [
    RaffleComponent,
    RaffleExpiredComponent,
    PurchaseComponent,
    SalesStatsComponent,
  ]
})
export class RaffleModule { }