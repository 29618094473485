import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { Seller, Sale, Product, SaleCart } from '@app/types';
import { SaleService } from './sale.service';
import { SellerService } from './seller.service';
import { SaleCartService } from '@app/cart/salecart.service';

interface CartProduct {
  product: Product;
  quantity: number;
}

@Component({
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.css'],
})
export class ShopComponent implements OnInit {
  public seller: Seller;
  public sellercode: string;
  public sale: Sale;
  @Output() onAddToCart = new EventEmitter<number>();

  public coverImage: any;
  public cartProducts: CartProduct[];

  public confirmNoSeller = false;
  public sellerSearchText: string;
  public sellerSearchResults: Seller[];

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public sellerservice: SellerService,
    public cartservice: SaleCartService,
    public saleservice: SaleService,
  ) {
    this.sellerSearchResults = [];
  }

  ngOnInit(): void {
    this.saleservice.sale.subscribe(sale => {
      this.sale = sale;
      this.cartProducts = [];
      for (let product of sale.products) {
        this.cartProducts.push({
          product: product,
          quantity: undefined,
        });
      }
    });
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.sellercode = params.get('seller');
      if (this.sellercode) {
        this.sellerservice.getByCode(this.sale.id, this.sellercode).subscribe(
          seller => { this.seller = seller; }
        );
      }
    });
  }

  addToCart() {
    const sellercode = this.sellercode ? this.sellercode : '000';
    const sellername = this.seller ? this.seller.name : null;
    let items = new Array<SaleCart>();

    for (let newproduct of this.cartProducts) {
      if (newproduct.quantity > 0) {
        let item = new SaleCart();
        item.productcode = newproduct.product.code;
        item.productname = newproduct.product.name;
        item.quantity = newproduct.quantity;
        item.price = newproduct.product.price;
        if (sellercode) item.sellercode = sellercode;
        if (sellername) item.sellername = sellername;
        items.push(item);
      }
    }

    this.cartservice.addToCart(items);
    this.router.navigate(["cart"], { relativeTo: this.route.parent });
  }

  onBlur($event) {
    if (!this.sellercode && this.sellerSearchResults.length == 1) {
      // User clicked away from the Search box when there was only a single search result
      // 2021.Feb - Josh Melanie and I decided to auto-select the single result
      this.onNewSellerSelected(this.sellerSearchResults[0]);
    }
  }

  onNewSellerSelected(seller: Seller) {
    this.sellercode = seller.code;
    this.router.navigate([seller.code], { relativeTo: this.route });
  }

  searchSellers(event) {
    this.sellerservice.search(this.sale.id, event.query).subscribe(
      results => {
        this.sellerSearchResults = results;
      }
    );
  }

}
