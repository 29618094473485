import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';

import { SaleCartService } from './salecart.service';
import { SaleService } from '@app/sales/sale.service';
import { Sale, SaleTransaction } from '@app/types';

@Component({
  selector: 'app-cart',
  templateUrl: './salecart.component.html',
  styleUrls: ['./salecart.component.css']
})
export class SaleCartComponent implements OnInit, OnDestroy {
  public include_fees = false;
  public sale: Sale;
  private subscription: Subscription = null;
  public transaction: SaleTransaction;


  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public saleservice: SaleService,
    public cartservice: SaleCartService,
  ) {

  }

  ngOnInit(): void {
    // Enable include_fees by default
    this.include_fees = true;

    this.saleservice.sale.subscribe({
      next: sale => {
        this.sale = sale;
      }
    });
    this.cartservice.transaction.subscribe({
      next: trans => {
        if (trans == null) {
          // We don't have a transaction ID which means our cart is empty, which means either this
          // page was navigated to by mistake or (more likely) timed out
          // Redirect back to the purchase page
          this.router.navigate([".."], { relativeTo: this.route });
        }
        if (trans.status == "COMPLETE") {
          // Payment is complete for this transaction...
          this.cartservice.forgetTransaction();
          this.router.navigate(["thankyou"], { relativeTo: this.route.parent });
        }
        this.include_fees = this.cartservice.current_transaction.donation_fees > 0;

        if (this.include_fees) {
          // Re-calculate the donation fees in case their subtotal changed (added more tickets to their cart)
          let new_fees = this.cartservice.calculateStripeNetTotal(this.cartservice.current_transaction.subtotal).fee;
          if (new_fees != this.cartservice.current_transaction.donation_fees) {
            this.cartservice.current_transaction.donation_fees = new_fees;
            this.cartservice.save().subscribe();
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  emptyCart(): void {
    this.cartservice.empty().subscribe(
      data => {
        this.router.navigate([".."], { relativeTo: this.route });
      }
    );
  }

  onPaymentError(event) {
    this.cartservice.pollTransaction();
  }

  onPaymentSuccess(event) {
    this.cartservice.updateCustomerInfo(event.name, event.email, event.phone, event.ship_address.address1, event.ship_address.address2, event.ship_address.city, event.ship_address.state, event.ship_address.zip);
    // Start polling the server for our transaction status every 5 seconds
    console.log("Start polling transaction status");
    this.subscription = interval(5000).subscribe(val => this.cartservice.pollTransaction());
  }

  onToggleFees(event) {
    console.log("OnToggleFees!");
    if (this.include_fees) {
      this.cartservice.current_transaction.donation_fees = this.cartservice.calculateStripeNetTotal(this.cartservice.current_transaction.subtotal).fee;
    } else {
      this.cartservice.current_transaction.donation_fees = 0;
    }
    this.cartservice.save().subscribe();
  }
}
