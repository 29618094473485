import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Raffle, Sale } from '@app/types';
import { RaffleService } from '@app/raffle';
import { SaleService } from '@app/sales/sale.service';
import { AppRoutingService } from '@app/app-routing.service';


@Component({
    templateUrl: 'home.component.html',
})
export class HomeComponent {
    raffles: Raffle[];
    sales: Sale[];

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        //private appRoutingService: AppRoutingService,
        public raffleservice: RaffleService,
        public saleservice: SaleService,
    ) {
    }

    ngOnInit() {
        if (this.route.snapshot.url.length > 0) {
            //this.appRoutingService.initializeRoutes();
        }
        this.sales = [];
        this.raffleservice.getAll("active=1").subscribe(
            raffles => {
                this.raffles = raffles;
            }
        );
        this.saleservice.getAll({}, ["client"]).subscribe(sale => this.sales.push(sale));
    }
}