import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(private router: Router) {
  }
  
  ngOnInit() {
    // Allows for ngOnInit to be called on routing to the same routing Component since we will never reuse a route
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
        return false;
    };
  }
}
