<div class="grid mb-3">
  <div class="md:col-6 mx-auto">
    <p-card class="p-2 lg:p-3">
      <div>
        <h2 *ngIf="!seller || seller.code == '000'">Step 1</h2>
        <div *ngIf="seller && seller.code != '000'">
          <label>Buying from</label>
          <h3 class="text-orange">{{ seller.name }}
            <a class="btn btn-sm btn-outline-primary" [routerLink]="['..']">Change</a>
          </h3>
        </div>

        <div *ngIf="!seller || seller.code == '000'">
          <label for="sellersearch">Attribute this sale to (if known)</label>
          <p-autoComplete id="sellersearch" placeholder="STUDENT NAME OR CODE" [(ngModel)]="sellerSearchText"
            [suggestions]="sellerSearchResults" (completeMethod)="searchSellers($event)" [minLength]="2"
            [forceSelection]="true" field="name" (onSelect)="onNewSellerSelected($event)" (onBlur)="onBlur($event)">
          </p-autoComplete>
        </div>

        <div *ngIf="!(seller || confirmNoSeller)">
          Or, <a [routerLink]="[]" (click)="confirmNoSeller=true">Make purchase without attribution</a>
        </div>

      </div>
    </p-card>
  </div>
</div>

<div class="grid" *ngIf="seller || confirmNoSeller">
  <p-card class="p-2 xl:p-3">
    <div class="grid" *ngFor="let item of cartProducts">
      <div class="col-1">
        <h1 class="text-center">{{item.product.code}}</h1>
      </div>
      <div class="col-4 md:col-3 lg:col-1">
        <!--image carousel-->
        <div *ngFor="let url of item.product.image_urls">
          <img [src]="url" class="w-full">
        </div>
      </div>
      <div class="col-7">
        <h3>{{item.product.name}}</h3>
        <p>{{item.product.description}}</p>
      </div>
      <div class="col col-offset-4 sm:col-offset-8 lg:col-offset-0">
        <span class="text-700 text-2xl mr-3">{{item.product.price | pennies | currency}}</span>

        <p-inputNumber type="number" placeholder="Quantity" size="4" [showButtons]="true" [(ngModel)]="item.quantity">
        </p-inputNumber>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <div class="flex justify-content-end">
        <p-button class="" label="Add to Cart" icon="pi pi-plus" (click)="addToCart()"></p-button>
      </div>
    </ng-template>

  </p-card>
</div>