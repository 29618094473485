import { Component, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { Product, ProductAdapter, Sale, SaleAdapter, Seller, SellerAdapter } from '@app/types';
import { SaleService } from '../sale.service';
import { SellerService } from '../seller.service';

@Component({
  selector: 'app-sales-stats',
  templateUrl: './sales-stats.component.html',
  styleUrls: ['./sales-stats.component.css']
})
export class SalesStatsComponent implements OnInit {
  public sale: Sale;
  public login_error = "";
  public products: Product[];
  public seller: Seller;
  public seller_code: string;
  public stats_pass: string;
  public stats: ReplaySubject<{}>;
  public show_login = false;
  public transactions = [];
  public total = 0;

  constructor(
    public saleservice: SaleService,
    public sellerservice: SellerService,
  ) { }

  ngOnInit(): void {
    // Wire up our stats watcher
    this.stats = new ReplaySubject<{}>(1);
    this.stats.subscribe({
      next: stats => {
        this.products = [];
        let pa = new ProductAdapter();
        stats["products"].forEach(product => {
          this.products.push(pa.adapt(product));
        });
        this.seller = (new SellerAdapter).adapt(stats["seller"]);
        this.transactions = stats["transactions"];
        this.total = stats["total"];
      }
    });
    // Try to query for our stats first thing, in case we have a cookie with
    // saved credentials
    this.saleservice.sale.subscribe({
      next: sale => {
        this.sale = sale;

        this.sellerservice.getSalesStats(this.sale.id).subscribe({
          next: stats => {
            this.stats.next(stats);
          },
          error: error => {
            this.show_login = true;
          }
        });
      }
    });
  }

  forgetme() {
    this.sellerservice.forgetSalesStats(this.sale.id).subscribe({
      next: response => {
        this.seller = null;
        this.transactions = [];
        this.total = 0;
        this.show_login = true;
      }
    });
  }

  onSubmitLogin() {
    this.show_login = false;
    this.sellerservice.getSalesStats(this.sale.id, this.seller_code, this.stats_pass).subscribe({
      next: stats => {
        this.stats.next(stats);
      },
      error: error => {
        if (error.status == 401 && this.seller_code) {
          this.login_error = "Invalid username/password";
        }
        this.show_login = true;
      }
    });
  }
}
