import { Injectable, Injector } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Router, Routes } from '@angular/router';

import { environment } from '@environments/environment';
import { RaffleComponent } from './raffle/raffle.component';
import { RaffleService } from './raffle/raffle.service';
import { SaleComponent } from './sales/sale.component';
import { SaleService } from './sales/sale.service';
import { PageNotFoundComponent } from './_helpers/page-not-found.component';
import { HomeComponent } from './home/home.component';
import { map } from 'rxjs/operators';


const initialLandingRoute: Routes = [
  { path: '**', component: HomeComponent }
];

const raffleWithCustomDomain: Routes = [
  {
    path: '', component: RaffleComponent,
    loadChildren: () => import('./raffle/raffle-routing.module').then(m => m.RaffleRoutingModule)
  },
  { path: '**', component: PageNotFoundComponent }
];

const raffleWithoutCustomDomain: Routes = [
  { path: '', component: HomeComponent },
  {
    path: ':raffle', component: RaffleComponent,
    loadChildren: () => import('./raffle/raffle-routing.module').then(m => m.RaffleRoutingModule)
  },
  { path: '**', component: PageNotFoundComponent }
];

const saleWithCustomDomain: Routes = [
  {
    path: '', component: SaleComponent,
    loadChildren: () => import('./sales/sale-routing.module').then(m => m.SaleRoutingModule)
  },
  { path: '**', component: PageNotFoundComponent }
];

const saleWithoutCustomDomain: Routes = [
  { path: '', component: HomeComponent },
  {
    path: ':sale', component: SaleComponent,
    loadChildren: () => import('./sales/sale-routing.module').then(m => m.SaleRoutingModule)
  },
  { path: '**', component: PageNotFoundComponent }
];


@Injectable()
export class AppRoutingService {
  constructor(
    private location: Location,
    private http: HttpClient,
    private raffleService: RaffleService,
    private saleService: SaleService,
    private injector: Injector,
  ) { }

  initializeRoutes() {
    const url = `${environment.apiUrl}/current_events`;
    return this.http.get(url).pipe(
      map(response => {
        const host = window.location.hostname;
        const router: Router = this.injector.get(Router);
        if (host in response) {
          if (response[host]["type"] == "raffle") {
            //console.log(`Mapping session to raffle ${response[host]["code"]}`);
            this.raffleService.initWithCode(response[host]["code"]);
            router.resetConfig(raffleWithCustomDomain);
            return;
          } else if (response[host]["type"] == "sale") {
            //console.log(`Mapping session to sale ${response[host]["code"]}`);
            this.saleService.initWithCode(response[host]["code"]);
            router.resetConfig(saleWithCustomDomain);
            return;
          }
        }
        if (this.location.path()) {
          let tokens = this.location.path().split("/");
          let event_code = tokens[1];
          if (event_code in response) {
            if (response[event_code]["type"] == "raffle") {
              //console.log(`Mapping session to raffle ${response[host]["code"]}`);
              this.raffleService.initWithCode(response[event_code]["code"]);
              router.resetConfig(raffleWithoutCustomDomain);
              return;
            } else if (response[event_code]["type"] == "sale") {
              //console.log(`Mapping session to sale ${response[host]["code"]}`);
              this.saleService.initWithCode(response[event_code]["code"]);
              router.resetConfig(saleWithoutCustomDomain);
              return;
            }
          }
        }

        console.log(`No domain match and no event code in the URL... default landing page`);
        router.resetConfig(initialLandingRoute);
        //router.initialNavigation();
      })
    ).toPromise();
  }
}
