<div *ngIf="sale">
  <div class="card card-rounded">
    <div class="card-body">
      <h3 class="text-orange">Ended</h3>
      <p>
        We're sorry, but this fund raising event has concluded.
      </p>
      <p>
        Thank you for your support of {{this.sale.client.name}}!
      </p>
    </div>
  </div>
</div>