import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { RaffleService } from './raffle.service';
import { Raffle } from '@app/types';

import { CartService } from '@app/cart';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-raffle',
  templateUrl: './raffle.component.html',
  styleUrls: ['./raffle.component.css']
})
export class RaffleComponent implements OnInit {
  rafflecode: string;
  raffle: Raffle;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public raffleservice: RaffleService,
    public cartservice: CartService,
    public titleservice: Title,
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.rafflecode = params.get('raffle');
      if (this.rafflecode) {
        this.raffleservice.initWithCode(this.rafflecode);
      }
    });
    this.raffleservice.raffle.subscribe({
      next: raffle => {
        this.raffle = raffle;
        this.rafflecode = raffle.code;
        this.titleservice.setTitle(`${this.raffle.client.name} - ${this.raffle.name}`);

        // Confirm we are within the sale dates for this Raffle
        let now = new Date();
        if (now < this.raffle.start || now > this.raffle.end) {
          // Raffle has expired...
          if (!this.router.url.endsWith("expired")) {
            this.router.navigate(["expired"], { relativeTo: this.route });
          }
        } else {
          // Not expired!
          if (this.router.url.endsWith("expired")) {
            // ...but somehow they followed a link to /expired ??
            this.router.navigate([".."], { relativeTo: this.route });
          }
        }
      }
    });
  }

}
