import { Component, OnInit } from '@angular/core';
import { Raffle } from '@app/types';
import { RaffleService } from './raffle.service';

@Component({
  selector: 'raffle-expired',
  templateUrl: './expired.component.html',
  styleUrls: ['./expired.component.css']
})
export class RaffleExpiredComponent implements OnInit {
  public raffle: Raffle;

  constructor(
    public raffleservice: RaffleService,
  ) {
    this.raffleservice.raffle.subscribe(raffle => this.raffle = raffle);
  }
  ngOnInit(): void {
  }
}
