import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, OnDestroy } from '@angular/core';

import barcodeScanListener from './barcode-scan-listener';

@Directive({
  selector: '[barcodeListener]'
})
export class BarcodeListenerDirective implements OnDestroy {
  @Output() onScan:EventEmitter<any> = new EventEmitter();
  @Input() scanDuration = 75;
  @Input() prefix = '';
  @Input() patternMatch = /..+/; // default regex is any 2-or-more characters
  @Input() finishScanOnMatch: boolean;
  private removeListener;

  constructor(
    private el: ElementRef,
  ) {
    // barcodeScanListener attached itself to the root document to listen for key events
    this.removeListener = barcodeScanListener.listen(
      {},
      (barcode) => this.onScan.emit(barcode)
    );
  }

  ngOnDestroy() {
    this.removeListener();
  }

}
