import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { StripeCardComponent, StripeFactoryService, StripeInstance } from 'ngx-stripe';
import { StripeCardElementChangeEvent, StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { ClientStripeData } from '@app/types';


@Component({
  selector: 'pay-stripe',
  templateUrl: './pay-stripe.component.html',
  styleUrls: ['./pay-stripe.component.css']
})
export class PayStripeComponent implements OnInit {
  @Input() amount: number;
  @Input() client_secret: string;
  @Input() client_stripe_data: ClientStripeData;
  @Input() loading: boolean;
  @Input() require_ship_address = false;
  @Output() error = new EventEmitter<any>();
  @Output() success = new EventEmitter<object>();
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  public stripe: StripeInstance;
  public stripe_error: any;

  public customer_email: string;
  public customer_name: string;
  public customer_phone: string;
  public include_fees = false;

  public ship_address1: string;
  public ship_address2: string;
  public ship_city: string;
  public ship_state: string = "MS";
  public ship_zip: string;

  public payment_form_complete = false;
  public processing = false;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#276fd3',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };

  // optional parameters
  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  public states = [
    { name: 'Alabama', abbrev: 'AL' },
    { name: 'Alaska', abbrev: 'AK' },
    { name: 'Arizona', abbrev: 'AZ' },
    { name: 'Arkansas', abbrev: 'AR' },
    { name: 'California', abbrev: 'CA' },
    { name: 'Colorado', abbrev: 'CO' },
    { name: 'Connecticut', abbrev: 'CT' },
    { name: 'Delaware', abbrev: 'DE' },
    { name: 'Florida', abbrev: 'FL' },
    { name: 'Georgia', abbrev: 'GA' },
    { name: 'Hawaii', abbrev: 'HI' },
    { name: 'Idaho', abbrev: 'ID' },
    { name: 'Illinois', abbrev: 'IL' },
    { name: 'Indiana', abbrev: 'IN' },
    { name: 'Iowa', abbrev: 'IA' },
    { name: 'Kansas', abbrev: 'KS' },
    { name: 'Kentucky', abbrev: 'KY' },
    { name: 'Louisiana', abbrev: 'LA' },
    { name: 'Maine', abbrev: 'ME' },
    { name: 'Maryland', abbrev: 'MD' },
    { name: 'Massachusetts', abbrev: 'MA' },
    { name: 'Michigan', abbrev: 'MI' },
    { name: 'Minnesota', abbrev: 'MN' },
    { name: 'Mississippi', abbrev: 'MS' },
    { name: 'Missouri', abbrev: 'MO' },
    { name: 'Montana', abbrev: 'MT' },
    { name: 'Nebraska', abbrev: 'NE' },
    { name: 'Nevada', abbrev: 'NV' },
    { name: 'New Hampshire', abbrev: 'NH' },
    { name: 'New Jersey', abbrev: 'NJ' },
    { name: 'New Mexico', abbrev: 'NM' },
    { name: 'New York', abbrev: 'NY' },
    { name: 'North Carolina', abbrev: 'NC' },
    { name: 'North Dakota', abbrev: 'ND' },
    { name: 'Ohio', abbrev: 'OH' },
    { name: 'Oklahoma', abbrev: 'OK' },
    { name: 'Oregon', abbrev: 'OR' },
    { name: 'Pennsylvania', abbrev: 'PA' },
    { name: 'Rhode Island', abbrev: 'RI' },
    { name: 'South Carolina', abbrev: 'SC' },
    { name: 'South Dakota', abbrev: 'SD' },
    { name: 'Tennessee', abbrev: 'TN' },
    { name: 'Texas', abbrev: 'TX' },
    { name: 'Utah', abbrev: 'UT' },
    { name: 'Vermont', abbrev: 'VT' },
    { name: 'Virginia', abbrev: 'VA' },
    { name: 'Washington', abbrev: 'WA' },
    { name: 'West Virginia', abbrev: 'WV' },
    { name: 'Wisconsin', abbrev: 'WI' },
    { name: 'Wyoming', abbrev: 'WY' }
  ];

  constructor(
    public stripeFactory: StripeFactoryService,
  ) {
  }

  ngOnInit() {
    this.stripe = this.stripeFactory.create(this.client_stripe_data.publishable_key);
  }

  cardUpdated(result: StripeCardElementChangeEvent) {
    console.log(result);
    //this.element = result.element;
    this.payment_form_complete = result.complete;
    this.stripe_error = undefined;
  }

  public formComplete(): boolean {
    if (!this.payment_form_complete) {
      return false;
    }
    if (!(this.customer_name && this.customer_email && this.customer_phone)) {
      return false;
    }
    if (this.require_ship_address) {
      if (!(this.ship_address1 && this.ship_city && this.ship_state && this.ship_zip)) {
        return false;
      }
    }
    return true;
  }

  public payNow(): void {
    this.processing = true;
    this.stripe_error = null;

    this.stripe.confirmCardPayment(this.client_secret, {
      payment_method: {
        card: this.card.element,
        billing_details: {
          name: this.customer_name,
          email: this.customer_email,
          phone: this.customer_phone
        }
      },
      receipt_email: this.customer_email
    }).subscribe(result => {
      if (result.error) {
        this.stripe_error = result.error;
        console.error('got stripe error', result.error);
        this.error.emit(result.error);
        this.processing = false;
      } else {
        let event = {
          "name": this.customer_name,
          "email": this.customer_email,
          "phone": this.customer_phone,
          "ship_address": {
            "address1": this.ship_address1,
            "address2": this.ship_address2,
            "city": this.ship_city,
            "state": this.ship_state,
            "zip": this.ship_zip,
          }
        };
        console.log(`payment succeeded [event=${event}]`);
        this.success.emit(event);
      }
    });
  }
}
