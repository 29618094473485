import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BookService } from '@app/book';
import { CartService } from '../cart.service';
import { Raffle } from '@app/types';
import { RaffleService } from '@app/raffle';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.css']
})
export class ThankYouComponent implements OnInit {
  public raffle: Raffle;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public raffleservice: RaffleService,
    public bookservice: BookService,
    public cartservice: CartService,
  ) {
  }
  ngOnInit(): void {
    this.raffleservice.raffle.subscribe({
      next: raffle => {
        this.raffle = raffle;
      }
    });
    this.cartservice.transaction.subscribe({
      next: trans => {
        if (trans == null) {
          // We don't have a transaction ID which means our cart is empty, which means either this
          // page was navigated to by mistake or (more likely) timed out
          // Redirect back to the purchase page
          this.router.navigate([this.route.parent]);
        }
        if (trans.status == "COMPLETE") {
          // Payment is complete for this transaction...
          this.router.navigate(["thankyou"], {relativeTo: this.route.parent});
        }
      }
    });
  }
}
