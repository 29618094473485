import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SaleCartComponent } from '../cart/salecart.component';
import { SaleExpiredComponent } from './expired.component';
import { ShopComponent } from './shop.component';
import { ThankYouComponent } from './thankyou/thankyou.component';
import { SalesStatsComponent } from './stats/sales-stats.component';

const routes: Routes = [
  { path: '', component: ShopComponent },
  { path: 'cart', component: SaleCartComponent },
  { path: 'expired', component: SaleExpiredComponent },
  { path: 'stats', component: SalesStatsComponent },
  { path: 'thankyou', component: ThankYouComponent },
  { path: ':seller', component: ShopComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class SaleRoutingModule { }