import { Observable, Observer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class JsonApiPager {
  constructor(
    private http: HttpClient,
  ) {
  }

  get(url): Observable<object> {
    return Observable.create((observer) => {
      let abort = false;
      let processPage = (request: Observable<any>, observer: Observer<object>) => {
        request.subscribe(response => {
          response['data'].forEach(item => {
            observer.next(item);
          });
          if (!abort) {
            if (response['links']['next']) {
              processPage(this.http.get(response['links']['next']), observer);
            } else if (response['next_page_url']) {
              processPage(this.http.get(response['next_page_url']), observer);
            }
          }
        });
      }
      processPage(this.http.get(url), observer);

      return () => { abort = true; }
    });
  }

}
