<div *ngIf="stripe" class="justify-content-md-center">
  <p-card header="Payment Information">
    <div class="field grid">
      <div class="col-12">
        <ngx-stripe-card [stripe]="stripe" [options]="cardOptions" [elementsOptions]="elementsOptions" containerClass="p-inputtext p-component p-element w-full"
          (change)="cardUpdated($event)" (error)="stripe_error = $event"></ngx-stripe-card>
        <p-message severity="error" *ngIf="stripe_error" [text]="stripe_error?.message"></p-message>
      </div>
    </div>
    <div class="field grid">
      <label for="customer_name" class="col-12 md:col-2">Name</label>
      <div class="col-12 md:col-10">
        <input type="text" pInputText class="w-full" name="customer_name" id="customer_name"
          [(ngModel)]="customer_name">
      </div>
    </div>
    <div class="field grid">
      <label for="customer_email" class="col-12 md:col-2">Email</label>
      <div class="col-12 md:col-10">
        <input type="email" pInputText class="w-full" name="customer_email" id="customer_email"
          [(ngModel)]="customer_email">
      </div>
    </div>
    <div class="field grid">
      <label for="customer_phone" class="col-12 md:col-2">Phone</label>
      <div class="col-12 md:col-10">
        <input type="tel" pInputText class="w-full" name="customer_phone" id="customer_phone"
          [(ngModel)]="customer_phone">
      </div>
    </div>

    <div *ngIf="require_ship_address">
      <div class="p-card-title">Delivery Address</div>
      <div class="field grid">
        <label for="ship_address1" class="col-12 md:col-2">Address</label>
        <div class="col-12 md:col-10">
          <input type="text" pInputText class="w-full" name="ship_address1" id="ship_address1"
            [(ngModel)]="ship_address1">
        </div>
        <div class="col-12 md:col-10 md:col-offset-2">
          <input type="text" pInputText class="w-full" name="ship_address2" id="ship_address2"
            [(ngModel)]="ship_address2">
        </div>
      </div>
      <div class="field grid">
        <label for="ship_city" class="col-12 md:col-2">City</label>
        <div class="col-12 md:col-10">
          <input type="text" pInputText class="w-full" name="ship_city" id="ship_city" [(ngModel)]="ship_city">
        </div>
      </div>
      <div class="field grid">
        <label for="ship_state" class="col-12 md:col-2">State</label>
        <div class="col-12 md:col-4">
          <p-dropdown [options]="states" optionLabel="name" optionValue="abbrev" name="ship_state" id="ship_state"
            [(ngModel)]="ship_state"></p-dropdown>
        </div>
        <label for="ship_zip" class="col-12 md:col-2">Zip</label>
        <div class="col-12 md:col-4">
          <input type="text" pInputText class="w-full" name="ship_zip" id="ship_zip" [(ngModel)]="ship_zip">
        </div>
      </div>
    </div>

    <ng-template pTemplate="footer">
      <div class="mx-auto">
        <button pButton (click)="payNow()" [disabled]="!formComplete() || processing" [loading]="processing || loading"
          label="Pay {{amount | pennies | currency}}">
        </button>
      </div>
    </ng-template>
  </p-card>
</div>