import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { Raffle, RaffleAdapter } from '@app/types';

@Injectable({ providedIn: 'root' })
export class RaffleService {
  baseUrl = environment.apiUrl;
  public current_raffle: Raffle;
  public raffle: ReplaySubject<Raffle>;

  constructor(
    private http: HttpClient,
    private raffleadapter: RaffleAdapter,
  ) {
    this.raffle = new ReplaySubject<Raffle>(1);
    // Keep _raffle current
    this.raffle.subscribe({
      next: raffle => {
        this.current_raffle = raffle;
      }
    });
  }

  getAll(params: string): Observable<Raffle[]> {
    const url = `${environment.apiUrl}/raffles?${params}`;
    //console.log(`rafflesvc.getAll(${params})`);
    return this.http.get(url).pipe(
      map((response: any[]) => response["data"].map(item => this.raffleadapter.adapt(item)))
    );
  }

  getBackgroundURL(): string {
    return `${environment.apiUrl}/raffles/${this.current_raffle.id}/background`;
  }

  getBannerURL(): string {
    return `${environment.apiUrl}/raffles/${this.current_raffle.id}/banner`;
  }

  getBookletCoverURL() {
    return `${environment.apiUrl}/raffles/${this.current_raffle.id}/cover.png`;
  }

  getByCode(code: string, onlyActive = 0): Observable<Raffle> {
    return this.getAll(`code=${code}&active=${onlyActive}`).pipe(
      map((raffles: Raffle[]) => { return raffles[0]; })
    );
  }

  getById(id: string): Observable<Raffle> {
    const url = `${environment.apiUrl}/raffles/${id}`;
    return this.http.get(url).pipe(
      map((item: any) => this.raffleadapter.adapt(item))
    );
  }

  getHeadlineURL(): string {
    return `${environment.apiUrl}/raffles/${this.current_raffle.id}/headline`;
  }

  getLogoURL(): string {
    return `${environment.apiUrl}/raffles/${this.current_raffle.id}/logo`;
  }

  getCurrent(): Raffle {
    return this.current_raffle;
  }

  init(raffle: Raffle): void {
    this.raffle.next(raffle);
  }
  initWithCode(raffle_code: string): void {
    this.getByCode(raffle_code).subscribe(
      raffle => {
        this.init(raffle);
      }
    );
  }

  update(id: string, params) {
    return this.http.patch(`${environment.apiUrl}/raffles/${id}`, params)
      .pipe(map(x => {
        return x;
      }));
  }

  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/raffles/${id}`)
      .pipe(map(x => {
        return x;
      }));
  }
}