/* Original taken from https://github.com/goodeggs/barcode-scan-listener/blob/master/src/index.js
 * Modified 2020.08.17 by brandon@rafflebreeze.com */
/* eslint-env browser */

// borrowed from https://stackoverflow.com/questions/3579219/show-different-keyboard-character-from-the-typed-one-in-google-chrome/3580352#3580352
function getInputSelection(el) {
  let start = 0, end = 0, normalizedValue, range,
    textInputRange, len, endRange;

  if (!el) {
    el = document.activeElement;
  }
  if (typeof el.selectionStart == "number" && typeof el.selectionEnd == "number") {
    start = el.selectionStart;
    end = el.selectionEnd;
  } else {
    range = window.getSelection() || document.getSelection();

    if (range && range.parentElement() == el) {
      len = el.value.length;
      normalizedValue = el.value.replace(/\r\n/g, "\n");

      // Create a working TextRange that lives only in the input
      textInputRange = el.createTextRange();
      textInputRange.moveToBookmark(range.getBookmark());

      // Check if the start and end of the selection are at the very end
      // of the input, since moveStart/moveEnd doesn't return what we want
      // in those cases
      endRange = el.createTextRange();
      endRange.collapse(false);

      if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
        start = end = len;
      } else {
        start = -textInputRange.moveStart("character", -len);
        start += normalizedValue.slice(0, start).split("\n").length - 1;

        if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
          end = len;
        } else {
          end = -textInputRange.moveEnd("character", -len);
          end += normalizedValue.slice(0, end).split("\n").length - 1;
        }
      }
    }
  }

  return {
    start: start,
    end: end
  };
}

export default {
  /**
   * Listen for scan with specified characteristics
   * @param  {Number} [scanOptions.scanCharTime] - maximum time in milliseconds between keyup and keydown events for the
   *                  keystroke to be considered a barcode scanner instead of an actual keyboard
   * @param  {Number} [scanOptions.scanIdleTime] - maximum time in milliseconds between characters after which we assume
   *                  this is the end of the current barcode. Should be at least `scanCharTime` or strangeness could occur
   * @param  {Function} scanHandler - called with the results of the scan
   * @return {Function} remove this listener
   */
  listen({ scanCharTime = 30, scanIdleTime = 50 }, scanHandler) {
    if (typeof scanHandler !== 'function') {
      throw new TypeError('scanHandler must be a function');
    }

    let keydownTime = null;
    let inputIsScanner = undefined;
    let finishScanTimeoutId = null;
    let valueBuffer = '';
    const flushEvents = function () {
      if (valueBuffer) {
        let el = document.activeElement;
        if (document.activeElement instanceof HTMLInputElement) {
          let el = <HTMLInputElement>document.activeElement;
          //tacb876-03debugger;
          el.value = el.value.slice(0, el.selectionStart) + valueBuffer + el.value.slice(el.selectionEnd);
          el.dispatchEvent(new Event("input"));
        }
      }
      resetScanState();
    }
    const onKeyDown = function (e) {
      if (e.repeat) {
        // scanners will never set repeat, so this is a keyboard
        inputIsScanner = false;
        return flushEvents();
      }
      keydownTime = Date.now();
    };
    const onKeyPress = function (e) {
      console.log(`[${Date.now()}] KeyPress [${e.key}]`);
      valueBuffer += e.key;

      if (e.repeat) {
        // scanners will never set repeat, so this is a keyboard
        inputIsScanner = false;
        return flushEvents();
      }
      e.preventDefault();
      e.stopPropagation();
    };

    const onKeyUp = function (e) {
      console.log(`[${Date.now()}] KeyUp [${e.key}]`);
      inputIsScanner = true;

      if (Date.now() - keydownTime > scanCharTime) {
        // This keyup took too long!
        console.log("KeyUp took too long... this is a human keystroke");
        inputIsScanner = false;
        return flushEvents();
      }

      // Cancel our previous alarm and set a new one
      if (finishScanTimeoutId) {
        clearTimeout(finishScanTimeoutId);
      }
      finishScanTimeoutId = setTimeout(onScan, scanIdleTime);
    };

    const onScan = function () {
      console.log(`[${Date.now()}] timeout waiting for more scanner input... scan is complete!`);
      scanHandler(valueBuffer);
      resetScanState();
    };

    const resetScanState = function () {
      finishScanTimeoutId = null;
      valueBuffer = '';
    };

    const removeListeners = function () {
      document.removeEventListener('keyup', onKeyUp);
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keypress', onKeyPress);
    };
    document.addEventListener('keyup', onKeyUp);
    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keypress', onKeyPress);
    return removeListeners;
  },
};
