<div class="row">
  <div class="xl:col-9 mx-auto">

    <p-card header="Shopping Cart" class="p-3">
      <div *ngIf="cartservice.current_transaction && cartservice.getSummary() as summary">
        <p-table [value]="summary['cart']" styleClass="p-datatable-striped" breakpoint="680px">
          <ng-template pTemplate="header">
            <tr>
              <th>Delete</th>
              <th>Seller</th>
              <th>Product</th>
              <th>Price Each</th>
              <th>Quantity</th>
              <th>Subtotal</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-cartitem>
            <tr>
              <td class="text-center">
                <span class="p-column-title">Remove</span>
                <button pButton type="button" class="p-button-text p-button-danger"
                  (click)="cartservice.removeFromCart(cartitem)" icon="pi pi-trash" pTooltip="Remove from cart"
                  tooltipPosition="left"></button>
              </td>
              <td>
                <span class="p-column-title">Seller</span>
                {{cartitem.sellername ? cartitem.sellername : (cartitem.sellercode == '000' ? 'none' :
                cartitem.sellercode)}}
              </td>
              <td>
                <span class="p-column-title">Product</span>
                <span>
                  <b>{{cartitem.productcode}}</b>
                  {{cartitem.productname}}
                </span>
              </td>
              <td>
                <span class="p-column-title">Price Each</span>
                {{cartitem.price | pennies | currency}}
              </td>
              <td>
                <span class="p-column-title">Quantity</span>
                {{cartitem.quantity}}
              </td>
              <td>
                <span class="p-column-title">Subtotal</span>
                {{cartitem.quantity * cartitem.price | pennies | currency}}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <div class="grid">
          <div class="col-9 md:col-10 text-right font-bold">Subtotal</div>
          <div class="col-2 md:col-1 text-right">{{summary.subtotal | pennies | currency}}</div>
        </div>
        <div class="grid">
          <div class="col-9 md:col-10 text-right font-bold">
            <p-checkbox name="include_fees" [binary]="true" label="Offset Card Processing Fees
              ({{cartservice.calculateStripeNetTotal(summary.subtotal).fee | pennies |
              currency}})" [(ngModel)]="include_fees" (onChange)="onToggleFees($event)">
            </p-checkbox>
          </div>
          <div class="col-2 md:col-1 text-right">
            {{this.cartservice.current_transaction.donation_fees | pennies | currency}}
          </div>
        </div>
        <div class="grid" *ngIf="this.cartservice.current_transaction.credit">
          <div class="col-9 md:col-10 text-right font-bold">Credit</div>
          <div class="col-2 md:col-1 text-right">
            {{-this.cartservice.current_transaction.credit | pennies | currency}}
          </div>
        </div>
        <div class="grid">
          <div class="col-9 md:col-10 text-right font-bold">Total</div>
          <div class="col-2 md:col-1 text-right">
            {{this.cartservice.current_transaction.total | pennies | currency}}
          </div>
        </div>

      </div>
      <ng-template pTemplate="footer">
        <button pButton type="button" [routerLink]="['..']" icon="pi pi-plus" iconPos="left"
          label="Continue Shopping"></button>
        <button pButton type="button" class="p-button-outlined p-button-danger ml-2" (click)="emptyCart()"
          icon="pi pi-trash" iconPos="left" label="Empty Cart"></button>
      </ng-template>

    </p-card>
  </div>
</div>

<div class="grid" *ngIf="this.cartservice.current_transaction">
  <div class="lg:col-6 mx-auto">
    <pay-stripe *ngIf="sale.client.payment_processor == 'STRIPE'" [amount]="cartservice.current_transaction.total"
      [client_secret]="cartservice.current_transaction.payments[0]['processor_data']['payment_intent']['client_secret']"
      [client_stripe_data]="sale.client.stripe_data" [loading]="cartservice.current_transaction.is_updating"
      [require_ship_address]="true" (error)="onPaymentError($event)" (success)="onPaymentSuccess($event)">
    </pay-stripe>
  </div>
</div>