<div class="row">
  <div class="col-md m-auto">
    <div class="mx-auto my-4" [style]="{'width': '350px', 'max-width': '60%'}">
      <img [src]="raffleservice.getHeadlineURL()" width="100%">
    </div>
  </div>

  <div class="col-md ml-auto">
    <div class="card card-rounded m-4 p-2 lg:p-3">
      <div class="card-body">
        <h1 class="text-center text-orange">Buy Tickets Now</h1>

        <h2 class="my-3">Ticket Price: {{raffle.ticket_price | pennies | currency}} each</h2>

        <ul *ngIf="raffle.discount_tier1_break">
          <li>Buy {{raffle.discount_tier1_break}}, get
            <span *ngIf="raffle.discount_tier1_credit">{{raffle.discount_tier1_credit | pennies | currency}} off!</span>
            <span *ngIf="raffle.discount_tier1_freetickets">{{raffle.discount_tier1_freetickets}} free!</span>
          </li>
          <li *ngIf="raffle.discount_tier2_break">Buy {{raffle.discount_tier2_break}}, get
            <span *ngIf="raffle.discount_tier2_credit">{{raffle.discount_tier2_credit | pennies | currency}} off!</span>
            <span *ngIf="raffle.discount_tier2_freetickets">{{raffle.discount_tier2_freetickets}} free!</span>
          </li>
          <li *ngIf="raffle.discount_tier3_break">Buy {{raffle.discount_tier3_break}}, get
            <span *ngIf="raffle.discount_tier3_credit">{{raffle.discount_tier3_credit | pennies | currency}} off!</span>
            <span *ngIf="raffle.discount_tier3_freetickets">{{raffle.discount_tier3_freetickets}} free!</span>
          </li>
        </ul>

        <h4>Step 1</h4>
        <div *ngIf="book && book.code != '000'">
          <label>Buying tickets from</label>
          <h3 class="text-orange">{{ book.owner }}
            <a class="btn btn-sm btn-outline-primary" [routerLink]="['..']">Change</a>
          </h3>
        </div>

        <div *ngIf="!book || book.code == '000'">
          <label for="booksearch">Attribute this sale to (if known)</label>
          <p-autoComplete id="sellersearch" placeholder="STUDENT NAME OR CODE" [(ngModel)]="bookSearchText"
            styleClass="w-full" inputStyleClass="w-full" [suggestions]="bookSearchResults"
            (completeMethod)="searchBooks($event)" [minLength]="2" [forceSelection]="true" field="owner"
            (onSelect)="onNewBookSelected($event)" (onBlur)="onBlur($event)">
          </p-autoComplete>
        </div>

        <div *ngIf="!(book || confirmNoSeller)">
          Or, <a [routerLink]="[]" (click)="confirmNoSeller=true">Purchase tickets without attribution</a>
        </div>

        <div *ngIf="book || confirmNoSeller">
          <h4 class="my-2">Step 2</h4>
          <input type="text" class="form-control" name="quantity" placeholder="QUANTITY" [(ngModel)]="ticketQuantity">

          <div class="text-center mt-2 lg:mt-4">
            <button class="btn btn-orange btn-lg" (click)="addTicketsToCart()" [disabled]="!ticketQuantity">ADD TO
              CART</button>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>