import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { JsonApiPager } from '@app/shared/jsonapipager';
import { Sale, SaleAdapter } from '@app/types';

@Injectable({ providedIn: 'root' })
export class SaleService {
  baseUrl = environment.apiUrl;
  public current_sale: Sale;
  public sale: ReplaySubject<Sale>;

  constructor(
    private http: HttpClient,
    private jsonapi: JsonApiPager,
    private saleadapter: SaleAdapter,
  ) {
    this.sale = new ReplaySubject<Sale>(1);
    // Keep _raffle current
    this.sale.subscribe({
      next: sale => {
        this.current_sale = sale;
      }
    });
  }

  getAll(params = {}, expand = [], limit: number = null): Observable<Sale> {
    const url = new URL(`${environment.apiUrl}/sales`);
    let httpparams = new HttpParams({fromObject: params});
    if (expand)
      httpparams = httpparams.set('expand', expand.join(','));
    let stream = this.jsonapi.get(url + '?' + httpparams);
    if (limit) {
      httpparams = httpparams.set('limit', limit.toString());
      stream = stream.pipe(take(limit));
    }
    return stream.pipe(
      map((item: object) => this.saleadapter.adapt(item))
    );
  }

  getBackgroundURL(): string {
    return `${environment.apiUrl}/sales/${this.current_sale.id}/background`;
  }

  getBannerURL(): string {
    return `${environment.apiUrl}/sales/${this.current_sale.id}/banner`;
  }

  getByCode(code: string, expand = []): Observable<Sale> {
    return this.getAll({code: code}, expand, 1);
  }

  getById(id: string): Observable<Sale> {
    const url = `${environment.apiUrl}/sales/${id}`;
    return this.http.get(url).pipe(
      map((item: any) => this.saleadapter.adapt(item))
    );
  }

  getHeadlineURL(): string {
    return `${environment.apiUrl}/sales/${this.current_sale.id}/headline`;
  }

  getLogoURL(): string {
    return `${environment.apiUrl}/sales/${this.current_sale.id}/logo`;
  }

  getCurrent(): Sale {
    return this.current_sale;
  }

  init(sale: Sale): void {
    this.sale.next(sale);
  }
  initWithCode(sale_code: string): void {
    this.getByCode(sale_code, ["client", "products"]).subscribe(
      sale => {
        this.init(sale);
      }
    );
  }

  update(id: string, params) {
    return this.http.patch(`${environment.apiUrl}/sales/${id}`, params)
      .pipe(map(x => {
        return x;
      }));
  }

  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/sales/${id}`)
      .pipe(map(x => {
        return x;
      }));
  }
}