import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pennies'
})
export class PenniesToDollarsPipe implements PipeTransform {
  transform(value: number): number {
    return value / 100.0;
  }
}
