import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Seller, SellerAdapter } from '@app/types';

@Injectable({ providedIn: 'root' })
export class SellerService {
  baseUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private selleradapter: SellerAdapter,
  ) {
    this.http.options
  }

  forgetSalesStats(raffle_id: string): Observable<any> {
    const url = `${environment.apiUrl}/sales/${raffle_id}/sellerstats?forgetme`;
    return this.http.get(url, { withCredentials: true });
  }

  getByCode(raffle_id: string, book_code: string): Observable<Seller> {
    const url = `${environment.apiUrl}/sales/${raffle_id}/sellers?code=${book_code}`;
    return this.http.get(url).pipe(
      map((response: any[]) => response["data"].map(item => this.selleradapter.adapt(item))[0])
    );
  }

  getSalesStats(raffle_id: string, book_code = "", stats_pass=""): Observable<any> {
    const url = `${environment.apiUrl}/sales/${raffle_id}/sellerstats?code=${book_code}&pass=${stats_pass}`;
    return this.http.get(url, { withCredentials: true });
  }

  search(raffle_id: string, search: string, limit = null): Observable<Seller[]> {
    const url = `${environment.apiUrl}/sales/${raffle_id}/sellers?search=${search}`;
    return this.http.get(url).pipe(
      map((response: any[]) => response["data"].map(item => this.selleradapter.adapt(item)))
    );
  }
}