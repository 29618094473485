<div class="grid">
  <div class="lg:col-9 mx-auto">

    <p-card header="Shopping Cart" class="p-3">
      <div *ngIf="cartservice.current_transaction && cartservice.getSummary() as summary">
        <p-table [value]="summary['items']" styleClass="p-datatable-striped" responsiveLayout="stack" breakpoint="680px">
          <ng-template pTemplate="header">
            <tr>
              <th>Delete</th>
              <th>Seller</th>
              <th>Price Each</th>
              <th>Quantity</th>
              <th>Subtotal</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-cartitem>
            <tr>
              <td class="text-center">
                <span class="p-column-title">Remove</span>
                <!--button pButton type="button" class="p-button-text p-button-danger"
                  (click)="cartservice.removeFromCart(cartitem)" icon="pi pi-trash" pTooltip="Remove from cart"
                  tooltipPosition="left"></button-->
              </td>
              <td>
                <span class="p-column-title">Seller</span>
                {{cartitem.name ? cartitem.name : (cartitem.code == '000' ? 'none' : cartitem.code)}}
              </td>
              <td>
                <span class="p-column-title">Price Each</span>
                {{raffle.ticket_price | pennies | currency}}
              </td>
              <td>
                <span class="p-column-title">Quantity</span>
                {{cartitem.quantity}}
              </td>
              <td>
                <span class="p-column-title">Subtotal</span>
                {{cartitem.quantity * raffle.ticket_price | pennies | currency}}
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr>
              <td colspan="4" style="text-align: right">
                <span class="p-column-title">Fees</span>
                <p-checkbox name="include_fees" [binary]="true" label="Offset Card Processing Fees
                ({{cartservice.calculateStripeNetTotal(summary.subtotal).fee | pennies |
                currency}})" [(ngModel)]="include_fees" (onChange)="onToggleFees($event)">
                </p-checkbox>
              </td>
              <td>{{this.cartservice.current_transaction.donation_fees | pennies | currency}}</td>
            </tr>
            <tr *ngIf="this.cartservice.current_transaction.credit">
              <td colspan="4" style="text-align: right">Discount</td>
              <td>{{-this.cartservice.current_transaction.credit | pennies | currency}}</td>
            </tr>
            <tr>
              <td colspan="4" style="text-align: right">Total</td>
              <td>{{this.cartservice.current_transaction.total | pennies | currency}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <ng-template pTemplate="footer">
        <button pButton type="button" [routerLink]="['..']" icon="pi pi-plus" iconPos="left"
          label="Add More Tickets"></button>
        <button pButton type="button" class="p-button-outlined p-button-danger ml-2" (click)="emptyCart()"
          icon="pi pi-trash" iconPos="left" label="Empty Cart"></button>
      </ng-template>

    </p-card>
  </div>
</div>

<div class="grid" *ngIf="this.cartservice.current_transaction">
  <div class="lg:col-6 mx-auto">
    <pay-stripe *ngIf="raffle.client.payment_processor == 'STRIPE'" [amount]="cartservice.current_transaction.total"
      [client_secret]="cartservice.current_transaction.payments[0]['processor_data']['payment_intent']['client_secret']"
      [client_stripe_data]="raffle.client.stripe_data" [loading]="cartservice.current_transaction.is_updating"
      (error)="onPaymentError($event)" (success)="onPaymentSuccess($event)">
    </pay-stripe>
  </div>
</div>