import { Component, OnInit } from '@angular/core';
import { Sale } from '@app/types';
import { SaleService } from './sale.service';

@Component({
  selector: 'sale-expired',
  templateUrl: './expired.component.html',
  styleUrls: ['./expired.component.css']
})
export class SaleExpiredComponent implements OnInit {
  public sale: Sale;

  constructor(
    public saleservice: SaleService,
  ) {
    this.saleservice.sale.subscribe(sale => this.sale = sale);
  }
  ngOnInit(): void {
  }
}
