<ng-container *ngIf="sale">
  <div id="background" [style.background-image]="'url(' + saleservice.getBackgroundURL() + ')'">
    <div class="grid grid-nogutter" id="banner" [style]="sale.banner_style">
      <div class="col text-center my-auto p-2">
          <img [src]="saleservice.getBannerURL()" [style]='{"height": "auto", "max-height": "72px", "max-width": "100%"}'>
      </div>
      <div class="col-fixed text-center my-auto" style="width: 4rem;">
        <a [routerLink]="['cart']">
          <ng-container *ngIf="cartservice.itemCount()">
            <i class="pi pi-shopping-cart mr-3" [style]="{'font-size': '2em', 'color': 'white'}" pBadge [value]="cartservice.itemCount()"></i>
          </ng-container>
          <ng-container *ngIf="!cartservice.itemCount()">
            <i class="pi pi-shopping-cart mr-3" [style]="{'font-size': '2em', 'color': 'white'}"></i>
          </ng-container>
        </a>
      </div>
    </div>

    <div class="p-2 lg:p-5">
      <router-outlet></router-outlet>
    </div>

  </div>

  <div class="p-2 lg:p-5" style="background-color: #d8dfe7">
    <markdown *ngIf="sale.about" [data]="sale.about"></markdown>

    <markdown *ngIf="sale.terms" [data]="sale.terms"></markdown>
  </div>

</ng-container>

<div id="footer" class="navbar navbar-dark text-center">
  <div class="navbar-text text-muted m-auto">
    Powered By
    <a href="https://rafflebreeze.com/" target="_blank" rel="noopener">RaffleBreeze</a>
    • Copyright 2023
  </div>
</div>
