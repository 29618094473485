import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

import { CartTicket, Raffle, Transaction, TransactionAdapter } from '@app/types';
import { RaffleService } from '@app/raffle';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  public raffle: Raffle;
  public trans_id: string;
  public current_transaction: Transaction;
  public transaction: ReplaySubject<Transaction>;

  constructor(
    private http: HttpClient,
    public transadapter: TransactionAdapter,
    public raffleservice: RaffleService,
  ) {
    this.transaction = new ReplaySubject(1);
    this.transaction.subscribe({
      next: trans => {
        this.trans_id = trans.id;
        this.current_transaction = trans;
        this.saveToLocalStorage();
      }
    });
    // Each "cart" is specific to a single raffle, so we need to watch for if/when the raffle
    // changes and reload ourselves accordingly
    this.raffleservice.raffle.subscribe({
      next: raffle => {
        this.init(raffle);
      }
    });
  }

  addTickets(quantity: number, code: string, seller?: string) {
    let newtickets = [];
    for (let i = 0; i < quantity; i++) {
      newtickets.push(new CartTicket(code, seller));
    }

    if (!this.current_transaction) {
      this.httpCreateNewTransaction(newtickets).subscribe(trans => {
        // Emit the new transaction to any listeners
        this.transaction.next(trans);
      });
    } else {
      this.current_transaction.cart = this.current_transaction.cart.concat(newtickets);
      this.httpUpdateTransaction().subscribe(trans => {
        this.transaction.next(trans);
      });
    }
  }

  calculateStripeNetTotal(net_goal: number, currency = "USD") {
    /**
     * Calculate what amount you would have to charge in order to net the desired net_goal
     * from Stripe
     * 
     * Adapted from:
     *     https://stackoverflow.com/questions/36907341/stripe-fee-calculation
     *     (lafif <hello@lafif.me>)
     */
    var fees = {
      USD: { Percent: 0.029, Fixed: 30 },
      GBP: { Percent: 0.024, Fixed: 20 },
      EUR: { Percent: 0.024, Fixed: 24 },
      CAD: { Percent: 0.029, Fixed: 30 },
      AUD: { Percent: 0.029, Fixed: 30 },
      NOK: { Percent: 0.029, Fixed: 2 },
      JPY: { Percent: 0.036, Fixed: 0 },
      MXN: { Percent: 0.036, Fixed: 3 }
    };
    var _fee = fees[currency];
    var total = (net_goal + parseFloat(_fee.Fixed)) / (1 - parseFloat(_fee.Percent));
    var fee = total - net_goal;

    return {
      fee: Math.round(fee),
      total: Math.round(total)
    };
  }

  empty() {
    this.current_transaction.cart = [];
    return this.save();
  }

  forgetTransaction() {
    localStorage.removeItem(this.raffle.code);
    this.trans_id = null;
    this.current_transaction = null;
  }

  getStripePublishableKey(): string {
    return this.raffle.client.stripe_data.publishable_key;
  }

  getSummary() {
    // First collate all the distinct sellers and their quantities
    if (!this.current_transaction) {
      return {};
    }
    let sellers = {};
    let quantities = {};
    let summary = {
      items: [],
      free: this.current_transaction.freetickets,
      subtotal: this.current_transaction.subtotal,
      total: this.current_transaction.total,
    };
    for (let tik of this.current_transaction.cart) {
      sellers[tik.code] = tik.seller;
      if (!quantities[tik.code]) quantities[tik.code] = 0;
      quantities[tik.code]++;
    }
    for (let code in quantities) {
      summary["items"].push({
        code: code,
        name: sellers[code],
        quantity: quantities[code],
        subtotal: quantities[code] * this.raffle.ticket_price,
      });
    }

    return summary;
  }

  httpCreateNewTransaction(cart: any[]) {
    const url = `${environment.apiUrl}/transactions`;
    let data = {
      raffle_id: this.raffle.id,
      cart: cart,
    };
    return this.http.post(url, data).pipe(
      map(item => this.transadapter.adapt(item['data']))
    );
  }

  httpGetTransaction(id: string) {
    const url = `${environment.apiUrl}/transactions/${id}?expand=payments,tickets`;
    return this.http.get(url).pipe(
      map(item => this.transadapter.adapt(item['data']))
    );
  }

  httpUpdateTransaction() {
    const url = `${environment.apiUrl}/transactions/${this.trans_id}?expand=payments,tickets`;
    /*let data = {
      cart: this.current_transaction.cart,
      customer_name: this.current_transaction.customer_name,
      customer_email: this.current_transaction.customer_email,
      customer_phone: this.current_transaction.customer_phone,
    };*/
    return this.http.patch(url, this.current_transaction).pipe(
      map(item => this.transadapter.adapt(item['data']))
    );
  }

  init(raffle: Raffle): void {
    this.raffle = raffle;

    let json = localStorage.getItem(this.raffle.code);
    if (!json) return;
    let cartdata = JSON.parse(json);
    if (Date.now() - cartdata.time > (60 * 60 * 24 * 1000)) {
      // This data in localStorage is over 24 hours old, ignore it
      localStorage.removeItem(this.raffle.code);
      return;
    }
    this.trans_id = cartdata.transaction_id;
    this.pollTransaction();
  }

  pollTransaction(): void {
    if (this.trans_id) {
      this.httpGetTransaction(this.trans_id).subscribe(trans => {
        this.transaction.next(trans);
      }, error => {
        // Error fetching this transaction by ID... maybe invalid or stale?
        this.trans_id = null;
        this.transaction.next(null);
        this.saveToLocalStorage();
      });
    }
  }

  removeFromCart(cartitem: CartTicket) {
    if (!this.current_transaction) {
      // ???
      return;
    }
    let i = this.current_transaction.cart.indexOf(cartitem);
    if (i >= 0) {
      this.current_transaction.cart.splice(i, 1);
    }
    this.httpUpdateTransaction().subscribe(trans => {
      this.transaction.next(trans);
    });
  }

  save() {
    this.saveToLocalStorage();
    return this.httpUpdateTransaction().pipe(
      map(trans => {
        this.transaction.next(trans);
      })
    );
  }

  saveToLocalStorage() {
    let cartdata = {
      time: Date.now(),
      transaction_id: this.trans_id,
    };
    localStorage.setItem(this.raffle.code, JSON.stringify(cartdata));
  }

  updateCustomerInfo(name: string, email: string, phone: string) {
    if (name)
      this.current_transaction.customer_name = name;
    if (email)
      this.current_transaction.customer_email = email;
    if (phone)
      this.current_transaction.customer_phone = phone;
    this.save().subscribe();
  }
}
