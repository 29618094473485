import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// primeng things
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { TableModule } from 'primeng/table';

import { MarkdownModule } from 'ngx-markdown';
import { SaleRoutingModule } from './sale-routing.module';

import { SharedModule } from '@app/shared/shared.module';

import { SaleComponent } from './sale.component';
import { SaleExpiredComponent } from './expired.component';
import { SalesStatsComponent } from './stats/sales-stats.component';
import { ShopComponent } from './shop.component';
import { ThankYouComponent } from './thankyou/thankyou.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SaleRoutingModule,
    RouterModule,
    SharedModule,
    AccordionModule,
    AutoCompleteModule,
    BadgeModule,
    ButtonModule,
    CardModule,
    InputNumberModule,
    InputTextModule,
    MessageModule,
    TableModule,
    MarkdownModule.forChild(),
  ],
  declarations: [
    SaleComponent,
    SaleExpiredComponent,
    SalesStatsComponent,
    ShopComponent,
    ThankYouComponent,
  ],
  exports: [
    SaleComponent,
    SaleExpiredComponent,
    SalesStatsComponent,
    ShopComponent,
    ThankYouComponent,
  ]
})
export class SaleModule { }