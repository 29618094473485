import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SellerService } from '../seller.service';
import { SaleCartService } from '@app/cart/salecart.service';
import { Sale } from '@app/types';
import { SaleService } from '../sale.service';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.css']
})
export class ThankYouComponent implements OnInit {
  public sale: Sale;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public saleservice: SaleService,
    public sellerservice: SellerService,
    public cartservice: SaleCartService,
  ) {
  }
  ngOnInit(): void {
    this.saleservice.sale.subscribe({
      next: sale => {
        this.sale = sale;
      }
    });
    this.cartservice.transaction.subscribe({
      next: trans => {
        if (trans == null) {
          // We don't have a transaction ID which means our cart is empty, which means either this
          // page was navigated to by mistake or (more likely) timed out
          // Redirect back to the purchase page
          this.router.navigate([this.route.parent]);
        }
        if (trans.status == "COMPLETE") {
          // Payment is complete for this transaction...
          this.router.navigate(["thankyou"], {relativeTo: this.route.parent});
        }
      }
    });
  }
}
