import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { SaleService } from './sale.service';
import { Sale } from '@app/types';

import { SaleCartService } from '@app/cart/salecart.service';
import { Title } from '@angular/platform-browser';

@Component({
  templateUrl: './sale.component.html',
  styleUrls: ['./sale.component.css'],
})
export class SaleComponent implements OnInit {
  code: string;
  sale: Sale;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public saleservice: SaleService,
    public cartservice: SaleCartService,
    public titleservice: Title,
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.code = params.get('sale');
      if (this.code) {
        this.saleservice.initWithCode(this.code);
      }
    });
    this.saleservice.sale.subscribe({
      next: sale => {
        this.sale = sale;
        this.code = sale.code;
        this.titleservice.setTitle(`${this.sale.client.name} - ${this.sale.name}`);

        // Confirm we are within the sale dates for this Raffle
        let now = new Date();
        if (now < this.sale.start || now > this.sale.end) {
          // Raffle has expired...
          if (!this.router.url.endsWith("expired")) {
            this.router.navigate(["expired"], { relativeTo: this.route });
          }
        } else {
          // Not expired!
          if (this.router.url.endsWith("expired")) {
            // ...but somehow they followed a link to /expired ??
            this.router.navigate([".."], { relativeTo: this.route });
          }
        }
      }
    });
  }

}
