<div *ngIf="sale">
  <div class="card card-rounded">
    <div class="card-body">
      <h3 class="text-orange">Thank You!</h3>
      <p>
        Your purchase is complete. Thank you for your support of {{this.sale.client.name}}!
      </p>
      <p>
        <a [routerLink]="['..']">Continue Shopping</a>
      </p>
    </div>
  </div>
</div>